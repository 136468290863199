@import 'normalize.css';
@import 'reset-css';

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

img {
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

body,
button {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Balsamiq Sans';
}

button:focus,
input:focus,
optgroup:focus,
select:focus,
textarea:focus {
  outline: none;
}

body::-webkit-scrollbar {
  border-radius: 10px;
  background-color: #fff;
}

body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: none;
}

body::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: #dadee6;
  border-radius: 10px;
}

textarea,
input:matches([type='email'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='url']) {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
}

.rc-tooltip-inner {
  padding: 0 !important;
}

.dropdown-menu-component-item {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #dadee6;
  cursor: pointer;
}

.answer-override-cell {
  font-weight: normal;
  line-height: 1;
  padding: 10px 0;
}
.answer-override-cell > label {
  margin: 0;
}

.answer-override-cell > label > label {
  margin-top: 0;
}

.grid-with-overrides .override-cell .ag-cell-wrapper {
  align-items: flex-start;
}
